import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { MultiselectModelI } from "../../s25-multiselect/s25.multiselect.component";
import { AlertService } from "../../../services/alert.service";
import { jSith } from "../../../util/jquery-replacement";
import { EventService } from "../../../services/event.service";

@TypeManagerDecorator("s25-ng-bulk-edit-unassign")
@Component({
    selector: "s25-ng-bulk-edit-unassign",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                <p class="ngBold c-margin-bottom--half">
                    Select a date range in which you'd like to remove location assignments:
                </p>
                <s25-ng-editable-date-time
                    [val]="startDt"
                    [allowEmpty]="false"
                    (valChange)="onDateChange($event, 'start')"
                ></s25-ng-editable-date-time>
                <div class="c-margin-top--single">To</div>
                <s25-ng-editable-date-time
                    [val]="endDt"
                    [allowEmpty]="false"
                    (valChange)="onDateChange($event, 'end')"
                ></s25-ng-editable-date-time>
                @if (dateErrorMsg !== "") {
                    <div class="ngRed ngBold">
                        {{ dateErrorMsg }}
                    </div>
                }
                <div class="cn-alert cn-alert--info c-margin-top--single">
                    <div class="cn-alert__icon cn-icon c-red" name="alert--info">
                        <svg class="cn-svg-icon" role="img">
                            <title>Information Icon</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"
                            ></use>
                        </svg>
                    </div>
                    <div class="cn-alert__label" tabindex="0">
                        <div>Selecting no Locations will remove all from the events</div>
                    </div>
                </div>
                <s25-ng-multiselect-search-criteria
                    [(modelBean)]="modelBean"
                    honorMatching="true"
                    [type]="'locations'"
                    [useSecurity]="true"
                ></s25-ng-multiselect-search-criteria>
                <s25-ng-checkbox class="c-margin-left--quarter" [(modelValue)]="genPref">
                    Generate Preferences
                </s25-ng-checkbox>
                <s25-loading-inline data-model="{}"></s25-loading-inline>
                <s25-ng-bulk-edit-save
                    [submitFn]="updateFn"
                    [isDanger]="true"
                    [buttonText]="'Unassign Locations'"
                ></s25-ng-bulk-edit-save>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditUnassignComponent implements OnInit {
    @Input() itemTypeId?: number = undefined;
    @Input() itemType?: string = undefined;
    @Input() itemIds: number[] = undefined;

    init: boolean;
    itemName: string;
    isLoading: false;
    startDt = S25Util.date.toStartOfDay(new Date());
    endDt = S25Util.date.toEndOfDay(S25Util.date.clone(this.startDt));
    modelBean: MultiselectModelI = { showResult: true, showMatching: false };
    genPref: boolean = true;
    dateErrorMsg: string = "";

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }

    onDateChange(e: any, dt: string) {
        dt === "start" ? (this.startDt = e) : (this.endDt = e);
        this.startDt > this.endDt
            ? (this.dateErrorMsg = "End date cannot be less than start date")
            : (this.dateErrorMsg = "");
        this.cd.detectChanges();
    }

    validate() {
        return this.dateErrorMsg === "";
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        if (this.validate()) {
            let confirm = jSith.when(true);
            let msg = "You are about to unassign the selected locations, this cannot be undone. Are you sure?";
            if (this.modelBean.selectedItems.length === 0) {
                msg = "You are about to unassign ALL location assignments, this cannot be undone. Are you sure?";
            }
            confirm = AlertService.confirm(msg);
            return confirm.then((confirmed: boolean) => {
                if (confirmed) {
                    return EventService.updateEventUnassign(
                        this.itemIds,
                        this.startDt.toISOString(),
                        this.endDt.toISOString(),
                        this.genPref,
                        S25Util.toItemIds(this.modelBean.selectedItems),
                    );
                }
            }, this.done);
        }
    }

    done(error?: any) {
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.isLoading = false;
        error && S25Util.showError(error);
    }
}
