//@author travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkMap } from "../s25.bulk.edit.util";
import { S25BulkEditSaveApi } from "../save/s25.bulk.edit.save.api";
import { EventService } from "../../../services/event.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

/*
Generic bulk edit dropdown component for for edits that only require selecting something from a dropdown.
This should allow a pre-existing dropdown, or a generic/custom dropdown to be passed through...
*/
@TypeManagerDecorator("s25-ng-bulk-edit-dropdown")
@Component({
    selector: "s25-ng-bulk-edit-dropdown",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                <div class="ngBold">Select {{ this.modelBean.title }}</div>
                @if (modelBean.searchCriteriaName !== "eventRoles" || itemTypeId !== 1) {
                    <s25-ng-dropdown-search-criteria
                        [type]="this.modelBean.searchCriteriaName"
                        [(chosen)]="this.chosen"
                        [(staticItems)]="this.staticItems"
                        (chosenChange)="onChosen()"
                    ></s25-ng-dropdown-search-criteria>
                }
                @if (modelBean.searchCriteriaName === "eventRoles" && itemTypeId === 1) {
                    <s25-ng-role-dropdown
                        [excludeIds]="excludeIds"
                        [(chosen)]="this.chosen"
                        (chosenChange)="onChosen()"
                    ></s25-ng-role-dropdown>
                }
                <div class="c-margin-bottom--single"></div>
                @if (hasContactDropdown) {
                    <s25-ng-dropdown-search-criteria
                        [type]="'contacts'"
                        [(chosen)]="this.chosenContact"
                        [placeholder]="'Search contacts'"
                    ></s25-ng-dropdown-search-criteria>
                }
                <s25-ng-bulk-edit-save
                    [submitFn]="updateFn"
                    [buttonText]="'Update ' + this.modelBean.title"
                ></s25-ng-bulk-edit-save>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditDropdownComponent implements OnInit {
    @Input() itemTypeId: number = undefined; //1,2,4,6 etc. for org/space/resource
    @Input() itemType: string = undefined; //Dropdown type - see SearchCriteriaContext for options
    @Input() itemIds: number[];
    @Input() hasContactDropdown?: boolean = false;
    @Input() excludeIds?: number[] = [];
    init: boolean;
    type: string;
    chosen: any;
    modelBean: any;

    staticItems: any = [];

    chosenContact: any;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.modelBean = S25BulkMap[this.itemTypeId][this.itemType];

        //event bulk edit prim org doesn't have no primary org option, as well as eventFolder
        if (this.itemTypeId !== 1 && this.itemType !== "primaryOrg") {
            this.staticItems = [
                {
                    dropDownOrigName: "No " + this.modelBean.title,
                    isSelected: true,
                    itemId: 0,
                    itemName: "No " + this.modelBean.title,
                    noAnchorClick: true,
                    txt: "No " + this.modelBean.title,
                    val: "",
                },
            ];
        }

        this.init = true;
        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    onChosen() {
        this.chosen.itemId;
        S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
    }

    //BulkEditSave is subsrcribed to this and will act on return
    update() {
        let itemId = this.chosen && this.chosen.itemId;

        if (itemId === undefined) {
            this.modelBean.alterSelectMsg ? alert(this.modelBean.alterSelectMsg) : alert("Please select an item.");
            return false;
        }

        if (this.modelBean.searchCriteriaName === "eventRoles" && this.itemTypeId === 1) {
            // event role contacts add/remove
            if (this.hasContactDropdown) {
                if (!this.chosenContact?.itemId || this.chosenContact?.itemId === undefined) {
                    alert("Please select a contact.");
                    return false;
                }
                return this.modelBean.service(this.itemIds, this.chosen.itemId, this.chosenContact.itemId);
            } else {
                return this.modelBean.service(this.itemIds, this.chosen.itemId, "");
            }
        } else {
            if (this.modelBean.serviceName && this.modelBean.serviceName === "eventMicro") {
                const context = { typeId: itemId }; //TODO set this payloadItem like space below when ANG-4469 merge or go live
                return S25Util.all([EventService.getEtags(this.itemIds), this.modelBean]).then((resp) => {
                    return resp[1].service(this.itemIds, context, resp[0]).then(function (resp: any) {});
                });
            } else if (this.modelBean.serviceName && this.modelBean.serviceName === "spaceMicro") {
                if (itemId === 0) itemId = "";
                const payload = { idList: this.itemIds, [this.modelBean.payloadItem]: itemId };
                return this.modelBean.service(payload);
            } else if (this.modelBean.serviceName && this.modelBean.serviceName === "updateFolder") {
                //queryId, new folderId , eventIds, ANG-5202  change folders for events, queryId is null in this case
                return this.modelBean.service(null, itemId, this.itemIds);
            } else {
                return this.modelBean.service(this.itemIds, itemId);
            }
        }
    }
}
