//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { EventService } from "../../../services/event.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { jSith } from "../../../util/jquery-replacement";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";

@TypeManagerDecorator("s25-ng-bulk-edit-constraint")
@Component({
    selector: "s25-ng-bulk-edit-constraint",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                @if (this.profile.length > 0) {
                    <div class="c-margin-left--half  c-margin-bottom--half">
                        @for (p of this.profile; track p; let i = $index) {
                            <div class="c-margin-top--half c-padding-bottom--half">
                                @if (p && p.status != "del") {
                                    <div>
                                        <div class="c-svgIcon ngCpointer ngFloatRight">
                                            <svg
                                                (click)="removeProfile(p.profile_id)"
                                                class="c-svgIcon ngCpointer"
                                                role="img"
                                            >
                                                <title>Delete</title>
                                                <use
                                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                                ></use>
                                            </svg>
                                        </div>
                                        <div>
                                            <s25-ng-constraints
                                                [validateConstraint]="this.validateProfile"
                                                [profileModel]="p"
                                                (profileModelChange)="setProfileStatus(p.profile_id, i)"
                                            ></s25-ng-constraints>
                                            <!-- <s25-ng-constraint [validateConstraint]="this.validateProfile" [(profileModel)]="p" (profileModelChange)="setProfileStatus(p.profile_id, i)" ></s25-ng-constraint>  -->
                                        </div>
                                        <hr class="ngBold" />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                <div class="c-margin-left--half c-margin-bottom--half">
                    <button
                        class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                        (click)="addConstraint()"
                    >
                        New Constraint
                    </button>
                </div>
                <div class="c-margin-left--half c-margin-bottom--half c-margin-top--half">
                    <s25-ng-bulk-edit-save [submitFn]="saveFn" [buttonText]="'Save'"></s25-ng-bulk-edit-save>
                </div>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditConstraintComponent implements OnInit {
    @Input() chosenModels?: any = [];
    @Input() itemIds: number[];
    @Input() itemTypeId: number = 2;
    @Input() itemType: string; //see S25BulkMap for options
    init: boolean;
    profile: any = [];
    endDate: any = S25Util.date.toEndOfDay(
        S25Util.date.createDate(new Date().getFullYear() + 1, new Date().getMonth() + 1, 1),
    );
    startDate: any = S25Util.date.toStartOfDay(
        S25Util.date.createDate(new Date().getFullYear(), new Date().getMonth() + 1, 1),
    );
    payload: any = [];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.init = true;
        this.cd.detectChanges();
    }

    get saveFn() {
        return this.save.bind(this);
    }

    async save() {
        await this.decorateProfileDataToSave();
        return EventService.updateBulkEditContainer(this.payload);
    }

    validateProfile = (dt: Date) => {
        return true; //  bulk edit, start/end date can be out side of the folders/cabinets range
        //return S25Util.date.isBetweenEqual(dt, this.startDate, this.endDate) ? true : "Constraints must be inside the cabinet/folder dates.";
    };

    removeProfile(id: any) {
        let profile = S25Util.array.getByProp(this.profile, "profile_id", id);
        if (profile.status === "new") {
            S25Util.array.inplaceRemoveByProp(this.profile, "profile_id", id);
        } else {
            profile.status = "del";
        }
    }

    //bulk edit status is new, but leave the codes here, when we do event form migration might able to call this component
    setProfileStatus(id: any, i?: any) {
        let profile = S25Util.array.getByProp(this.profile, "profile_id", id);
        if (profile && profile.status != "new") {
            profile.status = "mod";
        }
    }

    addConstraint() {
        let getLen = this.profile.length;
        let newProfile = {
            profile_id: "new-" + getLen,
            profile_name: "",
            profile_code: "dnr",
            prof_use: -1,
            rec_type_id: 1,
            rec_type_name: "Recurrence Grammar",
            init_start_dt: S25Util.date.clone(this.startDate),
            init_end_dt: S25Util.date.toEndOfDay(S25Util.date.clone(this.startDate)),
            status: "new",
            dates: {
                evStartDt: new Date(this.startDate || S25Util.date.toStartOfDay(new Date())),
                evEndDt: S25Util.date.toEndOfDay(new Date(this.startDate || new Date())),
            },
        };
        this.profile[getLen] = newProfile;
        this.cd.detectChanges();
    }

    decorateAdHocList(model: any) {
        jSith.forEach(model.profileModel && model.profileModel.occurrences, (key, a) => {
            model.ad_hoc_datelist = S25Util.array.forceArray(model.ad_hoc_datelist);
            model.ad_hoc_datelist.push(S25Datefilter.transform(a, "yyyy-MM-dd"));
        });
    }

    // weeky, monthly and daily recTypeId = 1
    decorateProfileDataToSave = () => {
        console.log({ profile: this.profile });
        let profileTemp = S25Util.deepCopy(this.profile);
        let decoratedProfiles: any = {};
        let warning: any = [];
        let exclude: any = [];

        jSith.forEach(profileTemp, (key, c) => {
            this.decorateAdHocList(c);
            let recTypeId;

            switch (c.profileModel && c.profileModel.type) {
                case "adhoc":
                    recTypeId = 2;
                    break;
                case "dnr":
                    recTypeId = 0;
                    break;
                case "weekly":
                case "monthly":
                case "daily":
                    recTypeId = 1;
                    break;
                default:
                    recTypeId = c.rec_type_id;
            }

            if (c.prof_use === -1) {
                let retE: any = {
                    // profileId: null,  // new profile leave profileId or null
                    name: c.profile_name,
                    occurrenceDefn: {
                        recTypeId: recTypeId,
                        profileCode: ["dnr", "adhoc"].indexOf(c.profile_code) > -1 ? "" : c.profile_code,
                        initStartDt: S25Util.date.dropTZString(c.dates.evStartDt),
                        initEndDt: S25Util.date.dropTZString(c.dates.evEndDt),
                    },
                };
                c.ad_hoc_datelist ? (retE.occurrenceDefn.adHocDates = c.ad_hoc_datelist) : "";
                ["dnr", "adhoc"].indexOf(c.profile_code) > -1 ? delete retE.occurrenceDefn.profileCode : "";
                exclude.push(retE);
            } else {
                let retW: any = {
                    // profileId: null, // new profile leave profileId or null
                    name: c.profile_name,
                    occurrenceDefn: {
                        recTypeId: recTypeId,
                        profileCode: ["dnr", "adhoc"].indexOf(c.profile_code) > -1 ? "" : c.profile_code,
                        initStartDt: S25Util.date.dropTZString(c.dates.evStartDt),
                        initEndDt: S25Util.date.dropTZString(c.dates.evEndDt),
                    },
                };
                c.ad_hoc_datelist ? (retW.occurrenceDefn.adHocDates = c.ad_hoc_datelist) : "";
                ["dnr", "adhoc"].indexOf(c.profile_code) > -1 ? delete retW.occurrenceDefn.profileCode : "";
                warning.push(retW);
            }
        });
        warning.length > 0 ? (decoratedProfiles.warning = warning) : "";
        exclude.length > 0 ? (decoratedProfiles.exclude = exclude) : "";

        if (decoratedProfiles !== null) {
            this.payload = { idList: this.itemIds };
            this.payload.constraints = decoratedProfiles;
        }
    };
}
