import { DropDownItem } from "../../../pojo/DropDownItem";
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, signal } from "@angular/core";
import { B2bBindService, B2BStatus, B2BSummary } from "../../../services/b2b.bind.service";

@Component({
    selector: "s25-ng-bulk-b2b-bind",
    template: `
        <h2 class="c-margin-top--single">Back To Back Binding</h2>
        <p class="ngFinePrint">Define the criteria for binding back-to-back reservations for selected event(s).</p>
        <div class="inline-block c-margin-top--single">
            The same event contact for this role:
            <s25-ng-dropdown-search-criteria
                [type]="'eventRoles'"
                [(chosen)]="chosenRole"
            ></s25-ng-dropdown-search-criteria>
        </div>
        <div class="c-margin-top--single">
            <label>
                Maximum number of minutes separating events:
                <input type="number" [(ngModel)]="passingMinutes" min="0" />
            </label>
        </div>
        <div class="c-margin-top--single">
            <label>
                Headcount varies by at most this percentage:
                <s25-ng-editable-percentage
                    [alwaysEditing]="true"
                    [(val)]="headCountVariance"
                ></s25-ng-editable-percentage
            ></label>
        </div>

        <div class="c-margin-left--half c-margin-bottom--half c-margin-top--single">
            <s25-ng-bulk-edit-save [buttonText]="saveButtonText" [submitFn]="runBindFn" [buttonText]="'Update'">
            </s25-ng-bulk-edit-save>
        </div>

        @for (result of resultsSummary(); track result.runNumber) {
            <div class="c-margin-top--half c-margin-right--double">
                <h3>Summary (run # {{ result.runNumber }})</h3>
                <div>
                    <span
                        ><strong>{{ result.events }}</strong></span
                    ><span> events were processed.</span>
                </div>
                <div>
                    <span
                        ><strong>{{ result.profiles }}</strong></span
                    ><span> reservations were processed.</span>
                </div>
                <div>
                    <span
                        ><strong>{{ result.norole }}</strong></span
                    ><span> events did not have a contact with a matching role.</span>
                </div>
                <div>
                    <span
                        ><strong>{{ result.bound }}</strong></span
                    ><span> reservations were bound.</span>
                </div>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkB2bBindComponent {
    @Input() itemIds: number[];
    init: boolean = false;
    chosenRole: DropDownItem = { itemId: -3, itemName: "Instructor" };
    passingMinutes: number = 10;
    headCountVariance: number = 50;

    resultsSummary = signal<B2BSummary[]>([]);
    saveButtonText = "Bind";

    constructor(private cd: ChangeDetectorRef) {}

    get runBindFn() {
        return this.runBind.bind(this);
    }

    async runBind() {
        const results = await B2bBindService.runBackToBackBind(
            this.itemIds,
            +this.chosenRole.itemId,
            this.passingMinutes,
            this.headCountVariance,
        );

        let displayResult: B2BSummary = {
            runNumber: this.resultsSummary().length + 1,
            bound: 0,
            events: 0,
            locked: 0,
            norole: 0,
            profiles: 0,
        };

        results?.forEach((result: B2BStatus) => {
            displayResult[result.item] = result.count;
        });

        this.saveButtonText = "Re-run Bind";
        this.resultsSummary.update((summary) => [...summary, displayResult]);
        this.cd.detectChanges();
    }
}
