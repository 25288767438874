import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkMap } from "../s25.bulk.edit.util";
import { S25BulkEditSaveApi } from "../save/s25.bulk.edit.save.api";
import { EventService } from "../../../services/event.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { DropDownItem } from "../../../pojo/DropDownItem";

@TypeManagerDecorator("s25-ng-bulk-edit-event-owner")
@Component({
    selector: "s25-ng-bulk-edit-event-owner",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                <div class="ngBold">Select {{ this.modelBean.title }}</div>
                <div class="spacer"></div>
                <s25-toggle-button
                    [toggleLabel]="'mode'"
                    (modelValueChange)="onChangeMode($event)"
                    [(modelValue)]="isRole"
                    [falseLabel]="'Contact'"
                    [trueLabel]="'Role'"
                ></s25-toggle-button>
                <div class="c-margin-bottom--double c-margin-top--double c-margin-left--half"></div>
                @if (mode === "contact") {
                    <s25-contact-dropdown
                        [r25UserOnly]="true"
                        [(chosen)]="chosenContact"
                        [onLoad]="contactsLoatded"
                    ></s25-contact-dropdown>
                }
                @if (chosenContact && chosenContact.itemId && mode === "contact") {
                    <button class="aw-button aw-button--danger--transparent" (click)="removeContact()">Remove</button>
                }
                @if (mode === "role") {
                    <s25-ng-role-dropdown
                        [(chosen)]="chosenRole"
                        (chosenChange)="onChosenRole()"
                    ></s25-ng-role-dropdown>
                }
                @if (chosenRole && chosenRole.itemId && mode === "role") {
                    <button class="aw-button aw-button--danger--transparent" (click)="removeRole()">Remove</button>
                }
                <div class="c-margin-bottom--double c-margin-top--double c-margin-left--half"></div>
                <s25-ng-bulk-edit-save
                    [submitFn]="updateFn"
                    [buttonText]="'Update ' + this.modelBean.title"
                ></s25-ng-bulk-edit-save>
            </div>
        }
    `,
    styles: `
        .spacer {
            margin: 20px 20px;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditEventOwnerComponent implements OnInit {
    @Input() itemTypeId: number = 1;
    @Input() itemType: string;
    @Input() itemIds: number[];

    init: boolean;
    type: string;
    chosenRole: DropDownItem;
    modelBean: any;
    chosenContact: DropDownItem;
    mode: "contact" | "role" = "contact";
    isRole: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.modelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.init = true;
        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    contactsLoaded = () => S25LoadingApi.destroy(this.elementRef.nativeElement);

    onChosenRole() {
        this.chosenRole.itemId;
        S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
    }

    onChangeMode(e: any) {
        if (e) {
            this.mode = "role";
            this.removeContact();
        } else {
            this.mode = "contact";
            this.removeRole();
        }
        this.cd.detectChanges();
    }

    removeContact() {
        this.chosenContact = {};
        this.cd.detectChanges();
    }

    removeRole() {
        this.chosenRole = {};
        this.cd.detectChanges();
    }

    //BulkEditSave is subsrcribed to this and will act on return
    update() {
        let itemId = null;

        if (!this.chosenContact?.itemId && !this.chosenRole?.itemId) {
            this.mode === "contact" ? alert("Please select a contact.") : alert("Please select a role.");
            return false;
        }

        if (this.chosenContact?.itemId) {
            itemId = this.chosenContact?.itemId;
        } else if (this.chosenRole?.itemId) {
            itemId = this.chosenRole?.itemId;
        }

        return this.modelBean.service(this.itemIds, itemId, this.mode);
    }
}
