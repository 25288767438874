import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
    signal,
} from "@angular/core";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { EventService } from "../../../services/event.service";
import { UserprefService } from "../../../services/userpref.service";
import { Event } from "../../../pojo/Event";
import { EventStateConst } from "../../../services/event.state.change.service";
import { PreferenceService } from "../../../services/preference.service";
import State = Event.State;

@TypeManagerDecorator("s25-ng-bulk-edit-state")
@Component({
    selector: "s25-ng-bulk-edit-state",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                <s25-loading-inline data-model="{}"></s25-loading-inline>
                <select
                    [(ngModel)]="chosenStateId"
                    (change)="updateCheckboxVisibility(); resetForm()"
                    class="c-selectInput"
                    aria-label="Select State"
                >
                    @for (state of statesList; track state) {
                        <option [ngValue]="state.id">
                            {{ state.name }}
                        </option>
                    }
                </select>
                @if (showCheckboxes()) {
                    <div>
                        <s25-ng-checkbox
                            [(modelValue)]="convertPreferences.locations"
                            class="checkbox-block c-margin-top--single"
                            >Assign Location Preferences</s25-ng-checkbox
                        >
                        <s25-ng-checkbox [(modelValue)]="convertPreferences.resources" class="checkbox-block"
                            >Assign Resource Preferences</s25-ng-checkbox
                        >
                    </div>
                }
                <button
                    class="aw-button aw-button--danger--outline ngBlock c-margin-bottom--single c-margin-top--single"
                    [disabled]="isLoading"
                    (click)="runAction()"
                >
                    Update Event State
                </button>
                @if (successMsg) {
                    <div class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single" role="alert">
                        <div class="cn-alert__icon cn-icon" name="alert--info">
                            <svg class="cn-svg-icon" role="img">
                                <title>Success Alert</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                ></use>
                            </svg>
                        </div>
                        <div class="cn-alert__label">
                            <span>{{ successMsg }}</span>
                        </div>
                    </div>
                }
                @if (noPermEvents && noPermEvents.length > 0) {
                    <s25-ng-bulk-edit-issues-list
                        [title]="'No Permissions List'"
                        [items]="noPermEvents"
                    ></s25-ng-bulk-edit-issues-list>
                }
                @if (lockedEvents && lockedEvents.length > 0) {
                    <s25-ng-bulk-edit-issues-list
                        [title]="'Locked Events List'"
                        [items]="lockedEvents"
                    ></s25-ng-bulk-edit-issues-list>
                }
                @if (multiRouteEvents && multiRouteEvents.length > 0) {
                    <s25-ng-bulk-edit-issues-list
                        [title]="'Drafts With Multiple Folders List'"
                        [items]="multiRouteEvents"
                    ></s25-ng-bulk-edit-issues-list>
                }
                @if (erroredEvents && erroredEvents.length > 0) {
                    <s25-ng-bulk-edit-issues-list
                        [title]="'Errors'"
                        [items]="erroredEvents"
                    ></s25-ng-bulk-edit-issues-list>
                }
            </div>
        }
    `,
    styles: `
        .checkbox-block {
            display: block;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditStateComponent implements OnInit {
    @Input() itemTypeId?: number = undefined;
    @Input() itemType?: string = undefined;
    @Input() itemIds?: number[] = [];

    init: boolean;
    itemName: string;
    modelBean: any;
    isLoading: false;
    successMsg: string;
    convertPreferences = { locations: true, resources: true };
    showCheckboxes = signal(false);

    chunkSize = 50;
    concurrentMax = 5;
    arrIndex = 0;
    chunkCount = 0;
    chunkIdx = 0;

    noPermEvents: S25ItemI[] = [];
    lockedEvents: S25ItemI[] = [];
    erroredEvents: S25ItemI[] = [];
    multiRouteEvents: S25ItemI[] = [];

    statesList: Event.State.Data[];
    chosenState: Event.State.Data;
    chosenStateId: Event.State.Id;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        const { preferences, allowedStates } = await this.getData();
        this.chosenStateId = parseInt(preferences?.SpbkEvState?.value ?? "1");
        this.statesList = this.mappedStates(allowedStates);
        this.chunkCount = Math.ceil(this.itemIds.length / this.chunkSize);
        this.modelBean = S25BulkMap[this.itemTypeId][this.itemType];

        this.updateCheckboxVisibility();

        this.init = true;
        this.cd.detectChanges();
    }

    updateCheckboxVisibility() {
        this.showCheckboxes.set([State.Ids.Confirmed, State.Ids.Tentative].includes(this.chosenStateId));
    }

    resetForm() {
        this.noPermEvents = [];
        this.lockedEvents = [];
        this.erroredEvents = [];
        this.multiRouteEvents = [];
        this.successMsg = "";
        this.cd.detectChanges();
    }

    runAction() {
        this.resetForm();
        S25LoadingApi.init(this.elementRef.nativeElement);
        let batchHandler: any = (chunkIdx: any) => {
            this.chunkIdx++;
            let start = chunkIdx * this.chunkSize; //0,         chunkSize,     2 * chunkSize...
            let end = start + this.chunkSize; //     chunkSize, 2 * chunkSize, 3 * chunkSize...
            //example: chunkCount: 3, chunkSize: 50, events: 147
            //first chunk: 0 to chunkSize - 1 (the minus 1 is bc slice goes until end - 1)
            // => 0 to 49
            //last  chunk: (this.chunkCount - 1) * this.chunkSize to  (this.chunkCount - 1) * this.chunkSize + this.chunkSize
            // => 2 * 50 to 2 * 50 + 50 => 100 to 150
            let chunk = this.itemIds.slice(start, end);
            return S25Util.all([EventService.getEtags(chunk), this.modelBean]).then((resp) => {
                return resp[1].service(chunk, this.chosenStateId, resp[0], this.convertPreferences).then(
                    (success: any) => {
                        return success;
                    },
                    (error: any) => {
                        if (error?.status === 403) return error; //error handler can give user meaningful message from 403
                        throw error;
                    },
                );
            });
        };

        S25Util.batch(batchHandler, this.concurrentMax, this.chunkCount).then(
            (resp) => {
                this.handleErrors(resp);
                this.cd.detectChanges();
                this.done();
            },
            (error) => {
                this.handleErrors(error);
                this.cd.detectChanges();
                this.done(error);
            },
        );
    }

    handleErrors(resp: any) {
        const perms = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "noPerm") || [];
        const locked = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "locked") || [];
        const error = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "errors") || [];
        const messages = this.extractMessages(resp);
        const noRoute = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "info") || [];

        //We don't let the user choose a folder in bulk edit so clean up the message
        noRoute.forEach((item: any) => {
            item.message = item.message.replace(
                "choose a new parent from the list",
                "more than one parent folder is available",
            );
        });

        this.noPermEvents = this.noPermEvents.concat(perms);
        this.lockedEvents = this.lockedEvents.concat(locked);
        this.erroredEvents = this.erroredEvents.concat(error).concat(messages);

        this.multiRouteEvents = this.multiRouteEvents.concat(noRoute);
        error.length === 0 ? (this.successMsg = "Success!") : "";
    }

    extractMessages(resp: any) {
        const messages = S25Util.propertyGet(S25Util.propertyGet(resp, "content"), "messages") || [];

        for (let message of messages) {
            let details: any = S25Util.array
                .forceArray(S25Util.propertyGet(resp, "items"))
                .find((item: any) => item.id === message.objectId);

            message.event_name = details?.name;
            message.reference = details?.eventLocator;
            message.message = message.message?.replace("/^Space/g", "Location").replace("[rsrv]", "");
        }

        return messages;
    }

    done(error?: any) {
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.isLoading = false;
        error && S25Util.showError(error);
    }

    getData() {
        return Promise.all([
            PreferenceService.getPreferences(["config_BPE_event_get", "SpbkEvState"]),
            UserprefService.getAllowedStates(),
        ]).then(([preferences, allowedStates]) => {
            return { preferences, allowedStates };
        });
    }

    mappedStates(allowedStates: Event.State.Id[]) {
        return allowedStates.map((id) => {
            return {
                id: id,
                name: EventStateConst.stateMap[id],
            } as Event.State.Data;
        });
    }
}
