//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-assignee")
@Component({
    selector: "s25-ng-bulk-edit-assignee",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        @if (this.init) {
            <div class="c-margin-top--half">
                <s25-ng-task-contacts-picker
                    [isOnBody]="true"
                    [isBulkEdit]="true"
                    (contactsAdded)="onChange($event)"
                ></s25-ng-task-contacts-picker>
                @if (this.itemsList && this.itemsList.length > 0) {
                    <div class="c-margin-top--half">
                        <div>{{ this.assigneeType }} :</div>
                        @for (i of this.itemsList; track i) {
                            <div class="c-margin-left--half">
                                {{ i.itemName }}
                            </div>
                        }
                    </div>
                }
                <s25-ng-bulk-edit-save
                    [submitFn]="updateFn"
                    [buttonText]="this.saveButtonText"
                    [buttonDisabled]="this.buttonDisabled"
                ></s25-ng-bulk-edit-save>
                @if (this.isDone && this.errorCount === 0) {
                    <div class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single" role="alert">
                        <div class="cn-alert__icon cn-icon" name="alert--info">
                            <svg class="cn-svg-icon" role="img">
                                <title>Success Alert</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                ></use>
                            </svg>
                        </div>
                        <div class="cn-alert__label">
                            <div>
                                Success!
                                <div></div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditAssigneeComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemType?: any = undefined;
    @Input() itemIds: any = undefined;
    init: boolean = false;
    type: any;
    mappingModelBean: any;
    itemsList: any = [];
    assigneeType: string;
    todoItemIds: any = [];
    isDone: boolean = false;
    errorCount = 0;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType];
        //todo
        this.todoItemIds = S25Util.deepCopy(this.itemIds);
        this.todoItemIds = this.todoItemIds.filter(function (x: any) {
            return x.indexOf("todo") !== -1;
        });
        this.todoItemIds = this.todoItemIds.map((x: any) => x.replace("todo", ""));
        //others
        //this.itemIds = this.itemIds.map((x : any)  => x.replace('task_multi_',""));
        // this.itemIds = this.itemIds.map((x : any)  => x.replace('task',""));
        this.itemIds = this.itemIds.filter(function (x: any) {
            return x.indexOf("todo") === -1;
        });
        this.itemIds = await S25BulkEditUtil.getMulitTaskIds(this.itemIds);
        parseInt(this.itemTypeId) === 10 ? (this.itemIds = this.itemIds.map((x: any) => x.replace("todo", ""))) : "";

        this.init = true;
        this.cd.detectChanges();
    }

    onChange(event: any) {
        // if(event.type !== this.assigneeType) {
        //      this.itemsList = this.itemsList.concat(event.items);
        // }else{
        //      this.itemsList = event.items;
        // }
        this.assigneeType = event.type;
        this.itemsList = event.items;
        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        let payload: any = [];
        let itemListToSave: any = [];
        let assignNodeName = "assignContact";

        if (this.assigneeType === "Contact") {
            itemListToSave = this.itemsList.map(function (obj: any) {
                return { contactId: S25Util.parseInt(obj.itemId) };
            });
            assignNodeName = "assignContact";
        } else {
            itemListToSave = this.itemsList.map(function (obj: any) {
                return { groupId: S25Util.parseInt(obj.itemId) };
            });
            assignNodeName = "assignGroup";
        }

        if (this.itemIds && this.itemIds.length > 0) {
            payload.push({
                taskIdList: this.itemIds,
                [assignNodeName]: itemListToSave,
            });
        }

        if (
            this.todoItemIds &&
            this.todoItemIds.length > 0 &&
            assignNodeName !== "assignGroup" &&
            itemListToSave.length === 1
        ) {
            payload.push({
                todoIdList: this.todoItemIds,
                [assignNodeName]: itemListToSave,
            });
        }

        if (this.todoItemIds.length > 0 && assignNodeName === "assignGroup") {
            alert("To Do task can't add assignees by security group group. Todos will not be changed.");
        } else if (this.todoItemIds.length > 0 && itemListToSave.length > 1) {
            alert("To Do task can only add one assignee. Todos will not be changed.");
        }

        return this.mappingModelBean.service(payload).then((resp: any) => {
            let taskIds = this.itemIds.concat(this.todoItemIds);

            if (resp.data) {
                taskIds.forEach((id: any) => {
                    let task = S25Util.array.getByProp(resp.data, "taskId", id);
                    if (task && assignNodeName === "assignContact") {
                        itemListToSave.forEach((cont: any) => {
                            if (S25Util.array.isIn(task.contacts, "contId", cont.contactId)) {
                                cont.success = true;
                                task.success = "Success";
                            } else {
                                this.errorCount++;
                                cont.error = "Not Added";
                                task.error = "Not Added";
                            }
                        });
                    }
                });
                this.errorCount > 0
                    ? alert(
                          "Unable to add one or more contact(s) to the tasks. Make sure they have sufficient rights to approve it",
                      )
                    : "";
            }

            this.isDone = resp.info !== "No data to save";
            this.cd.detectChanges();
        });
    } //END update
}
