import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, signal } from "@angular/core";
import { KeyValue } from "@angular/common";
import { VcalExportResults, VcalGenerateService } from "../../../services/vcal.generate.service";

@Component({
    selector: "s25-ng-bulk-export-sis",
    template: `
        <h2 class="c-margin-top--double">Export To SIS</h2>
        Indicate how you want 25Live to handle "Needs Space" and "Exceeds Head Count" To Do's for your selected events.
        <br />
        Then select your Export Criteria based on To Do status. Click the "Export to SIS" button to complete the export.
        <form>
            @for (setting of settings | keyvalue; track setting) {
                <h3 class="c-margin-top--single c-margin-bottom--single">{{ setting.value.title }}</h3>
                @for (option of setting.value.options; track option) {
                    <div class="c-margin-left--half">
                        <label>
                            <input
                                type="radio"
                                name="{{ setting.key }}"
                                value="{{ option.value }}"
                                [(ngModel)]="setting.value.value"
                            />
                            {{ option.label }}
                        </label>
                    </div>
                }
            }
        </form>

        <div class="c-margin-left--half c-margin-bottom--half c-margin-top--single">
            <s25-ng-bulk-edit-save [buttonText]="saveButtonText" [submitFn]="runExportFn"> </s25-ng-bulk-edit-save>
            {{ result?.error }}
            <br />
            {{ result?.summary }}
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditExportSisComponent {
    @Input() itemIds: number[];
    result: VcalExportResults;

    settings = {
        needsSpaceTodos: {
            title: "Complete 'Needs Space' To Do's",
            id: "needsSpaceTodos",
            value: "some",
            options: [
                { value: "some", label: "Only if all event occurrences have been assigned a location." },
                {
                    value: "all",
                    label: "Regardless of To Do Status",
                },
            ],
        },
        headCountTodos: {
            title: "Complete 'Exceeds Head Count' To Do's",
            value: "some",
            options: [
                {
                    value: "some",
                    label: "Only if the Expected Head Count is within the capacity of the assigned location(s).",
                },
                {
                    value: "all",
                    label: "Regardless of To Do Status",
                },
            ],
        },
        incompleteTodos: {
            title: "Export Criteria",
            value: "some",
            options: [
                {
                    value: "some",
                    label: "Only export events with Completed To Do's",
                },
                {
                    value: "all",
                    label: "Export all events (Ignore incomplete To Do's)",
                },
            ],
        },
    };

    saveButtonText = "Export to SIS";

    constructor(private cd: ChangeDetectorRef) {}

    get runExportFn() {
        return this.runExport.bind(this);
    }

    async runExport() {
        this.result = await VcalGenerateService.generateVcal(
            this.itemIds,
            this.valueToBool(this.settings.incompleteTodos.value),
            this.valueToBool(this.settings.needsSpaceTodos.value),
            this.valueToBool(this.settings.headCountTodos.value),
        );

        this.cd.detectChanges();
    }

    valueToBool(value: string) {
        return value === "all";
    }
}
