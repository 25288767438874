import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkMap } from "../s25.bulk.edit.util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-activation")
@Component({
    selector: "s25-ng-bulk-edit-activation",
    template: `@if (this.init) {
        <div>
            <span class="c-margin-top--single c-margin-bottom--half c-margin-right--half ngInlineBlock ngBold"
                >Deactivate/Activate</span
            >
            <s25-toggle-button
                (modelValueChange)="this.onToggleChange($event)"
                [modelValue]="this.activation"
                [trueLabel]="trueLabel"
                [falseLabel]="falseLabel"
            ></s25-toggle-button>
            <s25-ng-bulk-edit-save
                [submitFn]="saveFn"
                [buttonText]="Update"
                [submitFn]="update"
            ></s25-ng-bulk-edit-save>
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditActivationComponent implements OnInit {
    @Input() itemIds: number[];
    @Input() itemTypeId: any = undefined;
    @Input() falseLabel: string = "Deactivate";
    @Input() trueLabel: string = "Activate";
    @Input() itemType: any;
    mappingModelBean: any;
    init = false;
    activation: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.mappingModelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.init = true;
        this.cd.detectChanges();
    }

    onToggleChange(event: any) {
        this.activation = event;
        console.log({ activte: this.activation });
        this.cd.detectChanges();
    }

    get saveFn(): any {
        return this.save.bind(this);
    }

    save() {
        let payload: any = {
            idList: this.itemIds,
            state: this.activation ? 1 : 0,
        }; //0 for inactive and 1 for active,  'active' & 'inactive' should also work.
        return this.mappingModelBean.service(payload);
    }
}
