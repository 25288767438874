import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25BulkEditUtil, S25BulkMap } from "../s25.bulk.edit.util";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { EventService } from "../../../services/event.service";
import { AlertService } from "../../../services/alert.service";
import { jSith } from "../../../util/jquery-replacement";
import { EventI } from "../../../pojo/EventI";

export interface EventIssueI {
    event_name: string;
    event_locator: string;
}

export interface NewStringI {
    itemId?: number;
    itemName?: string;
    origName?: string;
}

@TypeManagerDecorator("s25-ng-bulk-edit-event-title-name")
@Component({
    selector: "s25-ng-bulk-edit-event-title-name",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                <div class="ngBold">Regular Expression Find & Replace</div>
                <div>
                    <label for="find">Find <input type="text" [(ngModel)]="find" class="c-input" id="find" /></label>
                    <div>
                        <s25-ng-checkbox class="c-margin-left--quarter" (modelValueChange)="onChange($event, 'global')">
                            All Instances</s25-ng-checkbox
                        >
                        <div>
                            <div>
                                <s25-ng-checkbox
                                    class="c-margin-left--quarter"
                                    (modelValueChange)="onChange($event, 'caseSensitive')"
                                >
                                    Case Sensitive</s25-ng-checkbox
                                >
                            </div>
                        </div>
                        <div class="c-margin-bottom--half">
                            <label for="replace"
                                >Replace <input type="text" [(ngModel)]="replace" class="c-input" id="replace"
                            /></label>
                        </div>
                        <s25-loading-inline model="{}"></s25-loading-inline>
                        <div>
                            <button
                                class="aw-button aw-button--outline c-displayInlineBlock c-margin-bottom--single"
                                [disabled]="isLoading"
                                (click)="preview()"
                            >
                                Preview
                            </button>
                            <button
                                class="aw-button aw-button--primary c-displayInlineBlock c-margin-bottom--single c-margin-left--single"
                                [disabled]="isLoading"
                                (click)="runAction()"
                            >
                                Update Event {{ itemType === "name" ? "Names" : "Titles" }}
                            </button>
                        </div>
                        @if (message) {
                            <div class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single" role="alert">
                                <div class="cn-alert__icon cn-icon" name="alert--info">
                                    <svg class="cn-svg-icon" role="img">
                                        <title>Success Alert</title>
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                        ></use>
                                    </svg>
                                </div>
                                <div class="cn-alert__label">
                                    <span>{{ message }}</span>
                                </div>
                            </div>
                        }
                        @if (mode === "preview") {
                            <div>
                                <table class="rose-object-occurrences-table bulk-edit-table">
                                    <thead>
                                        <tr>
                                            <th>Original {{ itemType === "name" ? "Name" : "Title" }}</th>
                                            <th>New {{ itemType === "name" ? "Name" : "Title" }}</th>
                                            <th>Too Long?</th>
                                        </tr>
                                    </thead>
                                    @for (e of previewEvents; track e) {
                                        <tbody>
                                            <tr>
                                                <td>{{ e.origName || " " }}</td>
                                                <td>{{ e.itemName || " " }}</td>
                                                <td>{{ e.itemName.length > CHAR_LIMIT ? "Yes" : "No" }}</td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                        }
                        @if (!this.isLoading && this.noPermEvents.length) {
                            <s25-ng-bulk-edit-issues-list
                                [items]="thisnoPermEvents"
                                [title]="'No Permissions List'"
                                class="c-displayInlineBlock"
                            >
                            </s25-ng-bulk-edit-issues-list>
                        }
                        @if (!this.isLoading && this.lockedEvents.length) {
                            <s25-ng-bulk-edit-issues-list
                                [items]="this.lockedEvents"
                                [title]="'Locked Events List'"
                                class="c-displayInlineBlock"
                            >
                            </s25-ng-bulk-edit-issues-list>
                        }
                    </div>
                </div>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditEventTitleNameComponent implements OnInit {
    @Input() itemTypeId: number;
    @Input() itemIds: any = undefined; // eventIds
    @Input() itemType: string = "title";

    init: boolean;
    modelBean: any;
    title: string;
    find: string;
    replace: string = "";
    global: boolean;
    caseSensitive: boolean;
    isLoading: boolean = false;
    mode: string;
    message: string = "";
    ALERT_LIMIT: number = 256;
    CHAR_LIMIT: number;
    longNames: NewStringI[] = [];
    shortNames: NewStringI[] = [];
    eventProp: string = "";
    noPermEvents: EventIssueI[] = [];
    lockedEvents: EventIssueI[] = [];
    findRegExp: any; // would be undefined or set to new RegExp(this.find, flags);
    previewEvents: NewStringI[] = [];

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        this.modelBean = S25BulkMap[this.itemTypeId][this.itemType];
        this.CHAR_LIMIT = this.itemType === "title" ? 120 : 40;
        this.eventProp = this.itemType === "name" ? "event_name" : "event_title";
        this.title = this.modelBean.title;
        this.init = true;
        this.cd.detectChanges();
    }

    loading = () => {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.isLoading = true; // set loading here as getNewStrings() calling it, destory in cleanup ()
        this.noPermEvents = [];
        this.lockedEvents = [];
        this.cd.detectChanges();
    };

    cleanup(err?: any) {
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.isLoading = false;
        err && S25Util.showError(err);
        this.cd.detectChanges();
    }

    validate() {
        if (!this.itemIds || !this.itemIds.length) {
            alert("Please select some event(s).");
            return false;
        }

        if (S25Util.isUndefined(this.find) || this.find === "") {
            alert("Please enter something to 'find'");
            return false;
        }

        return true;
    }

    _getNewStrings(itemIds: [], findRegExp: string, replaceString: string, eventProp: string) {
        return EventService.getEventsMinimal(itemIds).then((events: EventI[]) => {
            let ret: NewStringI[] = [];
            events.forEach((event: EventI) => {
                var origName = ("" + S25Util.coalesce(event[eventProp], "")).trim();
                ret.push({
                    itemId: event.event_id,
                    origName: origName,
                    itemName: origName.replace(findRegExp, replaceString),
                });
            });
            return ret;
        });
    }

    getNewStrings() {
        this.loading();

        let flags = (this.caseSensitive ? "" : "i") + (this.global ? "g" : "");
        this.find = "" + S25Util.coalesce(this.find, "");
        this.replace = "" + S25Util.coalesce(this.replace, "");

        try {
            this.findRegExp = new RegExp(this.find, flags);
        } catch (e) {
            return jSith.reject(e);
        }

        this.longNames = [];
        this.shortNames = [];

        return this._getNewStrings(this.itemIds, this.findRegExp, this.replace, this.eventProp).then(
            (newStrings: NewStringI[]) => {
                newStrings.forEach((s: NewStringI) => {
                    if (s.itemName && s.itemName.length > this.CHAR_LIMIT) {
                        this.longNames.push(s);
                    } else if (this.eventProp === "event_name" && !s.itemName) {
                        //no blank event names
                        s.itemName = s.origName;
                        this.shortNames.push(s);
                    }
                });

                return newStrings;
            },
        );
    }

    preview() {
        if (!this.validate()) {
            return;
        }

        this.mode = null;
        this.previewEvents = [];
        return this.getNewStrings().then((newStrings: NewStringI[]) => {
            this.previewEvents = newStrings;
            this.mode = "preview";
            this.cleanup();
        }, this.cleanup);
    }

    getValueList(nameArr: NewStringI[], nameProp: string, truncated?: boolean) {
        let valueList = nameArr
            .map((s: any) => {
                return truncated ? s[nameProp].substring(0, this.CHAR_LIMIT) : s[nameProp];
            })
            .join("; ")
            .trim();
        if (valueList.length > this.ALERT_LIMIT) {
            valueList = valueList.substring(0, this.ALERT_LIMIT) + "...";
        }
        return valueList;
    }

    runAction() {
        this.message = "";
        if (!this.validate()) {
            return;
        }

        return this.getNewStrings().then((newStrings: NewStringI[]) => {
            let confirm = jSith.when(true),
                msg;
            if (this.longNames.length) {
                msg =
                    "The following new event " +
                    (this.itemType === "name" ? "names" : "titles") +
                    " are longer than " +
                    this.CHAR_LIMIT +
                    " characters and will be truncated. Please confirm if you would like to proceed: " +
                    this.getValueList(this.longNames, "itemName", true);
                confirm = AlertService.confirm(msg);
            }

            return confirm.then((confirmed: boolean) => {
                if (confirmed) {
                    if (this.longNames.length) {
                        //new event name/title too long, service did not truncation,
                        newStrings.forEach((s: NewStringI) => {
                            if (s.itemName.length > this.CHAR_LIMIT) {
                                s.itemName = s.itemName.substring(0, this.CHAR_LIMIT);
                            }
                        });
                    }

                    let blankEventNameConfirm = jSith.when(true);
                    if (this.shortNames.length) {
                        msg =
                            " Are you sure you would like to proceed? The following new event" +
                            (this.itemType === "name" ? "names" : "titles") +
                            " are longer than " +
                            this.CHAR_LIMIT +
                            "characters and will be truncated as shown. " +
                            this.getValueList(this.longNames, "itemName");
                        blankEventNameConfirm = AlertService.confirm(msg);
                    }

                    return blankEventNameConfirm.then((blankEventNameConfirmed) => {
                        if (blankEventNameConfirmed) {
                            this.mode = null;
                            return this.modelBean.service(newStrings).then((resp: any) => {
                                this.cleanup();
                                this.noPermEvents =
                                    S25Util.propertyGet(S25Util.propertyGet(resp, "noPerms"), "event") || [];
                                this.lockedEvents =
                                    S25Util.propertyGet(S25Util.propertyGet(resp, "locked"), "event") || [];
                                this.message = "Success!";
                                this.cd.detectChanges();
                            }, this.cleanup);
                        } else {
                            this.cleanup();
                        }
                    });
                } else {
                    this.cleanup();
                }
            });
        }, this.cleanup);
    }

    onChange(e: boolean, type: string) {
        type === "caseSensitive" ? (this.caseSensitive = e) : (this.global = e);
    }
}
