//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { EventService } from "../../../services/event.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { OlsService } from "../../../services/ols.service";
import { jSith } from "../../../util/jquery-replacement";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { EventI } from "../../../pojo/EventI";

@TypeManagerDecorator("s25-ng-bulk-edit-copy")
@Component({
    selector: "s25-ng-bulk-edit-copy",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
                <div class="c-margin-top--single copyFolder">
                    <span class="ngBold">Select Parent Cabinet for Copied Folders:</span>
                    @for (i of this.chosenModels; track i) {
                        <div>
                            <span class="c-margin-left--half">{{ i.itemName }}</span>
                        </div>
                    }
                </div>
                <div>
                    <s25-ng-hierarch-dropdown
                        [(items)]="this.cabinetTypes"
                        [searchEnabled]="true"
                        (chosenChange)="onChangeCabinetType($event)"
                    ></s25-ng-hierarch-dropdown>
                </div>
                <s25-ng-bulk-edit-save
                    [submitFn]="saveFn"
                    [buttonText]="'Copy'"
                    [buttonDisabled]="!this.copyEnabled || this.loading"
                ></s25-ng-bulk-edit-save>
                <!--
            <div *ngIf="this.doneCopied && this.copyItems.length > 0" class="ngBlock c-margin-top--half cn-alert cn-alert--warning" role="alert">
              <div>{{this.copyItems.length > 1? 'Folders were not copied' : 'Folder was not copied'}}, encountered an error trying to save the security settings:</div>
              <div *ngFor="let i of this.copyItems">
                {{i.itemName}}
              </div>
            </div>
            -->
                @if (this.message) {
                    <div class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single" role="alert">
                        <div class="cn-alert__icon cn-icon" name="alert--info">
                            <svg class="cn-svg-icon" role="img">
                                <title>Success Alert</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                ></use>
                            </svg>
                        </div>
                        <div class="cn-alert__label">
                            <div>
                                Success! Copied Folders:
                                <div>
                                    <span [innerHTML]="this.message"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditCopyComponent implements OnInit {
    @Input() chosenModels?: any = [];
    @Input() itemIds: number[];
    @Input() itemTypeId: number = 2;
    @Input() itemType: string; //see S25BulkMap for options
    init: boolean;
    copyEnabled: boolean = false;
    cabinetTypes: any = [];
    parentId: number = 0;
    parentName: string = "";
    loading: boolean = false;
    message: string = "";
    copyItems: number[];
    doneCopied: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        // get the copy event cabinet list, so new folder has option to select which cabient copy to.... in admin tool use the last folder parentId
        EventService.getEventById(this.chosenModels[this.chosenModels.length - 1].itemParentId).then((data) => {
            EventService.getEventListByEventTypeId(data.event_type_id).then((data) => {
                data = S25Util.array.forceArray(data);
                this.cabinetTypes = data.map((obj: any) => {
                    return { itemName: obj.name, itemId: obj.id };
                });
                this.init = true;
                this.cd.detectChanges();
            });
        });
    }

    onChangeCabinetType(e: any) {
        this.parentId = e.itemId;
        this.parentName = e.itemName;
        this.copyEnabled = true;
        this.loading = false;
    }

    get saveFn() {
        return this.save.bind(this);
    }

    save() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.loading = true;
        this.cd.detectChanges();
        this.copyItems = this.chosenModels;
        for (let i = 0; i < this.chosenModels.length; i++) {
            let copyEvent: EventI;
            EventService.postEvent().then((postData) => {
                this.chosenModels[i].eventId = postData.event_id;
                EventService.getEventById(this.chosenModels[i].itemId).then((data) => {
                    copyEvent = {
                        event_id: this.chosenModels[i].eventId,
                        event_name: data.event_name,
                        event_type_id: data.event_type_id,
                        parent_id: this.parentId,
                        //parent_name: this.parentName,
                        //cabinet_id: this.parentId, //folder cabinet same as parentId
                        //cabinet_name: this.parentName,
                        //node_type: data.node_type,
                        status: "new",
                    };

                    if (data.category) {
                        copyEvent.category = data.category;
                        copyEvent.category = S25Util.array.forceArray(copyEvent.category);
                        copyEvent.category.forEach((item: any) => {
                            item.status = "new";
                        });
                    }

                    if (data.organization) {
                        copyEvent.organization = data.organization;
                        copyEvent.organization = S25Util.array.forceArray(copyEvent.organization);
                        copyEvent.organization.forEach((item: any) => {
                            item.status = "new";
                        });
                    }

                    EventService.putEvent(copyEvent.event_id, copyEvent).then(
                        (resp: any) => {
                            if (resp.events) {
                                //after create new folder, then copy folder Ols
                                OlsService.copyCabinetFolderOls(
                                    1,
                                    this.chosenModels[i].itemId,
                                    copyEvent.event_id,
                                ).then((data: any) => {
                                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                                    this.message += copyEvent.event_name + "<br>";
                                    this.copyItems.splice(i, 1);
                                    this.cd.detectChanges();
                                });
                            } else {
                                S25Util.showError(resp, null);
                                return jSith.reject({ response: "error" });
                            }
                        },
                        function (error) {
                            let jsonErr = error && error.data && S25Util.prettifyJson(error.data);
                            if (jsonErr.results && jsonErr.results.msg) {
                                //alert(jsonErr.results.msg);
                                alert(
                                    copyEvent.event_name +
                                        " folder was not copied, encountered an error trying to save the security settings.",
                                );
                            } else if (
                                jsonErr.results &&
                                jsonErr.results.error_details &&
                                jsonErr.results.error_details.error_detail &
                                    jsonErr.results.error_details.error_detail.content
                            ) {
                                //alert(jsonErr.results.error_details.error_detail.content);
                                alert(
                                    copyEvent.event_name +
                                        " folder was not copied, encountered an error trying to save the security settings.",
                                );
                            } else if (jsonErr.results && jsonErr.results.error && jsonErr.results.error.msg) {
                                //alert(jsonErr.results.error.msg);
                                alert(
                                    copyEvent.event_name +
                                        " folder was not copied, encountered an error trying to save the security settings.",
                                );
                            }
                        },
                    );

                    i === this.chosenModels.length - 1 ? (this.doneCopied = true) : "";
                });
            });
        }
    }
}
